<template>
  <ul class="list-disc ml-2">
  <li
    v-for="packing in details"
    :key="packing.packing_detail_id"
  >{{ packing.packing_material }} : {{ packing.packing_layer }}</li>
  </ul>
</template>

<script>
export default {
  name: 'ListPackingMaterials',
  props: {
    details: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
}
</script>
